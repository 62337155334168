import { render, staticRenderFns } from "./RelocateEventItem.vue?vue&type=template&id=2c7d0a6a&scoped=true&"
import script from "./RelocateEventItem.vue?vue&type=script&lang=js&"
export * from "./RelocateEventItem.vue?vue&type=script&lang=js&"
import style0 from "./RelocateEventItem.vue?vue&type=style&index=0&id=2c7d0a6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7d0a6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VCheckbox})
