<template>
  <v-card
    class="relocate-event"
    color="primary"
    dark
    @click="onSelectEvent(event.ID)"
  >
    <v-card-title class="text-h5">
      <v-checkbox 
        hide-details
        :value="active"
      />
      <span class="event-title">
      {{date}} 
      </span>
      <span class="event-title" style="padding-left:30px;">
      {{time}} 
      </span>
      <span class="event-title" style="padding-left:30px;color: red;font-size:0.8rem" v-if="event.DIFF > 0">
      ({{timeDifference}})
      </span>
    </v-card-title>

    <v-card-subtitle>
      {{$t('booking.schedule', locale)}}: {{event.Alias}}
      <span v-if="!event.SameSchedule" style="color: red;font-size: 0.8rem;padding-left: 10px;">({{$t('booking.messages.distinctSchedule', locale)}})</span>
    </v-card-subtitle>

    <!--v-card-actions>
      <v-btn text>
        Listen Now
      </v-btn>
    </v-card-actions-->
  </v-card>
</template>
<script>
import utils from '@/services/utils'
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    selectedEvent : {
      type: String,
      default: null,
    },
    onSelectEvent: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    active: false,
  }),
  watch: {
    selectedEvent (v) {
      this.active = this.event.ID === v
    },
  },
  computed: {
    date () {
      return this.event.Date.split('T').shift()
    },
    time () {
      return utils.convertTime(this.event.Time)
    },
    timeDifference () {
      return utils.getMinutesToTimeFormated(this.event.DIFF)
    },
  }
}
</script>
<style scoped>
.actove {
  color: blue;
}
.relocate-event .event-title {
  padding-top: 15px;
}
</style>
