<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('booking.relocateReservations.title', locale) }}
    </h3>
    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    />
    <v-layout wrap v-if="bookingData" style="padding: 0 10px">
      <v-flex xs6>
        <b>
          {{ getTranslation(bookingData.ActivityName, locale) }}
        </b>
      </v-flex>
      <v-flex xs6 style="text-align: right;">
        ({{bookingData.Date}} {{bookingData.Time}})
      </v-flex>
      <v-flex xs4>
        <b>
          {{ $t('booking.reservations.owner', locale) }}:
        </b>
      </v-flex>
      <v-flex xs8 >
        {{bookingData.OwnerName}}
      </v-flex>
      <v-flex xs4></v-flex>
      <v-flex xs8>
        {{bookingData.OwnerEmail}}
      </v-flex>
    </v-layout>
    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    />
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <v-layout wrap>
      <v-flex xs12
        v-for="item of events"
        :key="item.ID"
        style="padding-bottom:20px"
      >
        <relocate-event-item
          :event="item"
          :selectedEvent="selectedEvent"
          :onSelectEvent="handleSelectEvent"
          :locale="locale"
        />
      </v-flex>
      <v-flex xs12>
        <v-btn v-if="count > events.length" block color="primary"
          @click="handleShowMore"
        >
          {{$t('common.showMore', locale)}}
        </v-btn>
      </v-flex>
    </v-layout>
    <action-buttons
      :id="editedID"
      :onClose="onClose"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :locale="locale"
    />
    <!--v-row 
      v-if="!loading && (!activities || activities.length === 0)"
      align="center" 
      style="margin: 20px 0 -10px 0;"
    >
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-calendar-question"
        width="100%"
      >
       {{ $t('booking.reservations.messages.noEventsForSelectedDate', locale) }}
      </v-alert>
    </v-row>
    <v-row v-else align="center" style="margin: 20px 0;">
      <v-autocomplete
        :label="$t('booking.activities.title', locale)"
        v-model="selectedActivity"
        :loading="loading"
        :items="activities"
        dense
        hide-no-data
        hide-details
        color="primary"
        item-text="name"
        item-value="id"
        @change="handleChangeActivity"
        outlined
      />
    </v-row>
    <v-row
      align="center"
      no-gutters
      v-for="event of eventsFiltered"
      :key="event.ID"
    >
      <v-col style="padding-top: 5px;">
        <v-btn
          color="primary"
          outlined
          @click="handleAddItem(event)"
          block
        >
        {{ convertTime(event.Time) }} → {{ $t('booking.reservations.addReservation', locale) }} 
        </v-btn>
      </v-col>
    </v-row-->
  </v-alert>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import ActionButtons from '@/components/ActionButtons'
import RelocateEventItem from './RelocateEventItem'
export default {
  components: {
    ActionButtons,
    RelocateEventItem,
  },
  props: {
    editedID: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    events: [],
    eventsFiltered: [],
    activities: [],
    selectedActivity: null,

    selectedEvent: null,
    count: 0,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',

    bookingData: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
  },
  watch: {
    editedID () {
      this.itemsPerPage = 5
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    handleShowMore () {
      this.itemsPerPage += 5
      this.handleGetAvailableEvents()
    },
    handleGetData () {
      this.handleGetBookingData()
      this.handleGetAvailableEvents()
    },
    handleGetBookingData () {
      this.bookingData = null
       api.getItem('booking', `v1/private/reservations/`, this.editedID)
        .then(response => {
          response.Date = response.Date.split('T').shift()
          response.Time = utils.convertTime(response.Time)
          this.bookingData = response
        })
    },
    handleGetAvailableEvents () {
      this.loading = true
      const query = `${this.search ? this.search : ''}`
      api.getAll('booking', `v1/private/reservations/${this.editedID}/relocate-events`, this.startIndex, this.itemsPerPage, query)
        .then(response => {
          if (response) {
            this.count = response.count
            this.events = response.data
          }
          this.loading = false
        })
    },
    handleSelectEvent (v) {
      this.selectedEvent = this.selectedEvent === v ? null : v
    },
    handleSave () {
      if (!this.selectedEvent) {
        alert(this.$t('booking.messages.selectedEventRequired', this.locale))
        return
      }
      api.updateItem('booking', `v1/private/reservations/`, `${this.editedID}/relocate-events`, { NewEventID: this.selectedEvent })
        .then(response => {
          const { error } = response.data
          if(error) alert(error)
          else this.onClose(true)
        })

    },
    handleDelete (v) {
      api.deleteItem('booking', `v1/private/reservations/`, v)
        .then(response => {
          if(response && response.data.status === 'OK') this.onClose('delete', v)
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
}
</script>

